import React from "react";
import Table from "./Table";
import { FaChevronDown, FaCheck, FaTimes } from "react-icons/fa";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import "../GlobalVariables";
import Button from "react-bootstrap/Button";
import baseUrl, { authentication, getClasses, getSubjects, getSubjectsForDropdown, getTeachers } from "../Utils";
import Select from "react-select";
import { Type } from "react-bootstrap-table2-editor";
import { getThemeProps } from "@material-ui/styles";


class Tests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      studentData: [],
      subjects: [],
      teachers: [],
      terms: [{value: 1, label: "Term 1"},
              {value: 2, label: "Term 2"}, 
              {value: 3, label: "Term 3"}],
      classes: []
    };
    this.getTests();
    getTeachers(this);
    getSubjects(this);
    getClasses(0, this);
  }

  convertToSelect(arrayToConvert) {
    return arrayToConvert.map((el) => {
      return {value: el.id, label: (el.name ? el.name : el.class)};
    });
  }

  formatFromDropdown(cell, row, rowIndex, extraData) {
    const retVal =  extraData.find((val) => {
      return (cell == val.value);
    });
    return (
      <div> 
        { retVal ? retVal.label  : cell }
      </div>
    );
  }

  getColumns() {
    return [
    {
      dataField: "date",
      text: "Date",
      sort: true,
      editable: false
    },
    {
      dataField: "subject",
      text: "Subject",
      sort: true,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.convertToSelect(this.state.subjects);
        },
      }, 
      formatter: (cell, row, rowIndex, extraData) => {
          return this.formatFromDropdown(cell, row, rowIndex, extraData);
      },
      formatExtraData: this.convertToSelect(this.state.subjects)
    },
    {
      dataField: "topic",
      text: "topic",
      sort: true,
    },
    {
      dataField: "grade",
      text: "Grade",
      sort: true,
      editable: false,
    },
    {
      dataField: "class",
      text: "Class",
      sort: true,

      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.convertToSelect(this.state.classes);
        },
      }, 
      formatter: (cell, row, rowIndex, extraData) => {
          return this.formatFromDropdown(cell, row, rowIndex, extraData);
      },
      formatExtraData: this.convertToSelect(this.state.classes)
    },
    {
      dataField: "teacher",
      text: "Teacher",
      sort: true,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.convertToSelect(this.state.teachers);
        },
      }, 
      formatter: (cell, row, rowIndex, extraData) => {
          return this.formatFromDropdown(cell, row, rowIndex, extraData);
      },
      formatExtraData: this.convertToSelect(this.state.teachers)
    },
    {
      dataField: "term",
      text: "Term",
      sort: true,
      editor: {
        type: Type.SELECT,
        getOptions: (setOptions, { row, column }) => {
          return this.state.terms;
        },
      }, 
      formatter: (cell, row, rowIndex, extraData) => {
          return this.formatFromDropdown(cell, row, rowIndex, extraData);
      },
      formatExtraData: this.convertToSelect(this.state.terms)
    },
    {
      dataField: "average",
      text: "Average",
      sort: true,
      editable: false
    },
  ];
}


  editFunction = (oldValue, newValue, row, column) => {
    if (oldValue != newValue && newValue) {
      let fieldName;
      if (column.dataField == "topic") {
        fieldName = "topic";
      } else {
        fieldName = column.dataField+"_id";
      }

      const formData = new FormData()
      formData.append('id', row.id)
      formData.append('field_name', fieldName)
      formData.append('value', newValue);

      fetch(`${baseUrl}/update-test`, {
        method: 'POST',
        headers: {
          Authorization: authentication,
        },
        body: formData,
      })
    }
  };

  getTests() {
    var url = "http://ohrhatorah.appsenses.com/test.php";
    fetch(`${baseUrl}/tests`, {
      headers: {
        Authorization: authentication,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        res.forEach((a) => {
          a.id = parseInt(a.id);
        });

        res.map((item) => {
          item.id = parseInt(item.id);
        });
        this.setState({ studentData: res });
      });
  }
  deleteTests = () => {
    let record = {};
    record.testIds = this.tableRef.handleGetSelectedData();
    let formData = new FormData();
    // formData.append("action","delete tests")
    formData.append("tests", JSON.stringify(record));
    //formData.append('record', JSON.stringify(record))
    var url = "http://ohrhatorah.appsenses.com/test.php";

    fetch(`${baseUrl}/delete-tests`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: authentication,
      },
    }).then((response) => {
      this.getTests();
    });

    /* return response.json();}).then(res=>{
            res.map((item)=>{
                item.id=parseInt(item.id)

            })
            this.setState({studentData:res})
        })*/
  };

  defaultSorted = [
    {
      dataField: "date", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  render() {
    return (
      <div>
        <Breadcrumb>
          <Breadcrumb.Item href="Home">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Tests</Breadcrumb.Item>
        </Breadcrumb>
        <div style={{ padding: "25px" }}>
          <div style={{ paddingBottom: "25px", textAlign: "right" }}>
            <Button
              onClick={() => {
                this.props.history.push({
                  pathname: "/new-test",
                });
              }}
            >
              New Test
            </Button>
          </div>

          {
            <Table
              ref={(ref) => (this.tableRef = ref)}
              switch={true}
              defaultSorted={this.defaultSorted}
              columns={this.getColumns()}
              data={this.state.studentData}
              deleteClick={this.deleteTests}
              editFunc={this.editFunction}
              rowEvents={{
                onClick: (e, row, rowIndex) => {
                  if (e.type == "click") {
                    this.props.history.push({
                      pathname: "/test-details",
                      state: {
                        testId: row.id,
                        topic: row.topic,
                        date: row.date,
                      },
                    });
                  }
                },
              }}
            />
          }
        </div>
      </div>
    );
  }
}

export default Tests;
